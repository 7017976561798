#menuAdmin {
  padding: 0 20px;
}
.cta-button, .button:not(.managerEditBtn), button, .readMore, .addToCalender {
  padding: .75em 1.5em!important;
  &:before {
    color: $brand-dark-blue;
    content: "\f1b0";
    font-family: 'FontAwesome';
    font-size: 1.25em;
    margin-right: 10px;
  }
  &.donate:before {
    content: "\f004";
    color: lighten($brand-orange, 15%);
  }
}
.mainCallToAction {
  .phone-no {
    display: none;
  }
}
.homeIntroWrapper {
  font-size: 1em;
}
.homeFeaturesWrapper {
  //padding: 5em 0;
}
.homeHeadlinesIntro {
  padding-top: 5em;
  h2{
  text-align: center;
    &.homeFeaturesTitle {
      display: none;
    }
  }
}

@media (min-width: map-get($breakpoints, lg)) {
  .feedItem .banner, .feedItem [class*="image-link"]  {
    border-radius: 10px;
  }
  .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper), .feedItem [class^="listed"][class$="Text"] {
    padding-top: 0;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }

}

.productPrice {
  color: $body-colour;
}

@media (max-width: map-get($breakpoints, lg)) {
  ul.carouselControls {
    align-items: flex-start;
  }
}

//BUG 286571

#termly-code-snippet-support button:before {

  content: ''!important;
  margin-right: 0;

}