//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  )
}

// Choose your card style for home feature cards
.homeFeatures .homeFeature {
  @include card;
  @include card-basic;
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 2
);

Specify a card layout for each individual feed
.homeFeedBox .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

// .homeFeedBox2 .feedList {
//   @include cards-layout-prefab (
//     $number-of-cards: 3,
//     $prefab: 2
//   );
// }

// Choose your card style for home feed cards
.feedItem {
  @include card;
  @include card-side-by-side (
      $card-side-by-side-image-width: 33%,
      $card-side-by-side-breakpoint: map-get($breakpoints, lg),
      $card-side-by-side-vertical-text-position: top,
      $card-side-by-side-grow-image: false
  );
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above, you can remove the ':not(.listedProduct)' selector above and squash this together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}
